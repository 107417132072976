import React from 'react'
import Image from 'next/image'

type Props = {
  className?: string
}

export const MainBlockCashRewardsImage = ({ className }: Props) => (
  <div className={className}>
    <Image
      src="/img/chart_ants@3x.png"
      width="555"
      height="416"
      alt="Ants chart"
    />
  </div>
)

export const MainBlockMoneyAntsImage = ({ className }: Props) => (
  <div className={className}>
    <Image
      src="/img/raining_ants@3x.png"
      width="881"
      height="606"
      alt="Raining money on ants"
    />
  </div>
)
