import Head from 'next/head'
import { useEffect } from 'react'
import type { GetServerSideProps } from 'next'
import { withRequiredOrOptionalSession } from '@web-app/hoc/withSession'
import { withOnboarding } from '@web-app/hoc/withOnboarding'
import { useEventTracking } from '@web-app/hooks/useEventTracking'
import { WebLandingLayout } from '@web-app/components/layout/WebLandingLayout'
import { HeroCashRewards } from '@ui/hero/HeroCashRewards'
import {
  MainBlockCashRewards,
  MainBlockHowYouEarn,
  MainBlockTitleUnderline,
  MainBlockJoinMillionsDownload,
  MainBlockFAQs,
  MainBlockTestimonials,
} from '@ui/main-blocks'
import {
  MainBlockCashRewardsImage,
  MainBlockMoneyAntsImage,
} from '@web-app/components/images'
import { earnFaqs } from '@ui/components/data'

const title = 'ATM.com - Earn Money - Real Cash Rewards'
const description =
  'Earn extra cash through paid surveys, games, featured offers, and daily rewards.'
const canonical = 'https://app.atm.com/'

const IndexPage = () => {
  const { trackEvent } = useEventTracking()
  const ctaUrl = '/auth/register'

  useEffect(() => {
    trackEvent('view_index', { comp: 'IndexPage' })
  }, [])

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonical} />
      </Head>
      <WebLandingLayout>
        <HeroCashRewards
          title="Earn Real Cash Today"
          description={
            <>
              Earn extra cash through surveys, games, and exclusive offers{' '}
              <span className="whitespace-pre">all in one app.</span>
            </>
          }
        />
        <MainBlockTitleUnderline variant="blueberry-700">
          Extra Cash, No Hassle!
        </MainBlockTitleUnderline>
        <MainBlockCashRewards>
          <MainBlockCashRewardsImage className="flex h-full w-full items-center justify-center" />
        </MainBlockCashRewards>
        <MainBlockTestimonials />
        <MainBlockTitleUnderline variant="blueberry-1000">
          Get Paid for Surveys, Games & More!
        </MainBlockTitleUnderline>
        <MainBlockHowYouEarn>
          <MainBlockMoneyAntsImage className="flex h-full w-full items-center justify-center" />
        </MainBlockHowYouEarn>
        <MainBlockTitleUnderline variant="off-white" />
        <MainBlockFAQs faqs={earnFaqs()} />
        <MainBlockJoinMillionsDownload
          headerText="Earn Extra Cash from Games, Surveys, and More!"
          ctaText="Get Started"
          ctaUrl={ctaUrl}
        />
      </WebLandingLayout>
    </>
  )
}

export const getServerSideProps: GetServerSideProps =
  withRequiredOrOptionalSession(
    { required: false },
    withOnboarding(async (_context) => {
      return { props: {} }
    }),
  )

export default IndexPage
